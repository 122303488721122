<template>
  <div class="lean-improvement-balance-wrapper pt-2 pb-3" v-if="game">
    <div class="buttons is-centered mb-2">
      <b-button @click="flexibleStations" type="is-info" rounded
        >Make all stations 100% flexible</b-button
      >
    </div>
    <div
      class="is-divider divider-text-darker"
      data-content="Or customize each station :"
    ></div>
    <div v-for="faci in facis" :key="faci.id">
      <b-field class="my-0 py-0">
        <p class="control label pt-2 pr-5 is-size-7">
          <b-icon :icon="faci.icon" size="is-small"></b-icon> {{ faci.name }}
          <a @click="renameFac(faci)">
            <b-icon type="is-success" icon="pencil" size="is-small"></b-icon>
          </a>
          <a @click="removeFac(faci)">
            <b-icon type="is-danger" icon="delete" size="is-small"></b-icon>
          </a>
          :
        </p>
        <b-field v-if="faci.params && faci.type === 'painting'">
          <b-field class="pl-1 my-0 py-0">
            <b-checkbox-button
              v-model="faci.params.availColors"
              v-for="col in Object.values(leanParams.availColors)"
              :key="`col-${col.id}-${faci.id}`"
              :native-value="col.id"
              :type="col.class"
              size="is-small"
            >
              {{ col.colorName }}
            </b-checkbox-button>
          </b-field>
          <b-field class="pl-3">
            <b-checkbox-button
              v-model="faci.params.availShapes"
              v-for="sha in Object.values(leanParams.availShapes)"
              :key="`sha-${sha.id}-${faci.id}`"
              :native-value="sha.id"
              type="is-info is-light"
              size="is-small"
            >
              <b-icon :icon="sha.icon"></b-icon>
              <span class="ml-2">{{ sha.shape | capitalize }}</span>
            </b-checkbox-button>
          </b-field>
        </b-field>
      </b-field>
      <div class="box-divider my-3"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "LeanImprovementBalance",
  props: {
    game: Object
  },
  computed: {
    facis() {
      return Object.values(this.newFacilities).filter(
        fac => this.availTypes[fac.type]
      );
    },
    ...mapGetters(["leanParams"])
  },
  mounted() {
    this.initializeData();
  },
  methods: {
    initializeData() {
      this.readyWatch = false;
      this.newFacilities = JSON.parse(JSON.stringify(this.game.facilities));
      setTimeout(() => {
        this.readyWatch = true;
      }, 5);
    },
    flexibleStations() {
      this.facis.forEach(fac => {
        if (fac.type === "painting") {
          fac.params.availColors = Object.keys(this.leanParams.availColors);
          fac.params.availShapes = Object.keys(this.leanParams.availShapes);
          fac.icon = "radiobox-marked";
        }
      });
    },
    renameFac(faci) {
      this.$buefy.dialog.prompt({
        message: `Update the station name :`,
        cancelText: "Cancel",
        size: "is-medium",
        confirmText: "Update",
        inputAttrs: {
          type: "text",
          value: faci.name,
          required: true,
          size: 40
        },
        type: "is-success",
        onConfirm: newName => {
          faci.name = newName;
        }
      });
    },
    removeFac(faci) {
      this.$buefy.dialog.confirm({
        message: `This will permanently remove the station : <b>${faci.name}</b>`,
        cancelText: "Cancel",
        size: "is-medium",
        confirmText: "Remove",
        type: "is-danger",
        onConfirm: () => {
          this.$emit("update", { type: "balanceremove", obj: faci });
          setTimeout(() => {
            this.initializeData();
          }, 5);
        }
      });
    }
  },
  watch: {
    newFacilities: {
      handler: function() {
        if (this.readyWatch) {
          this.$emit("update", { type: "balance", obj: this.newFacilities });
        }
      },
      deep: true
    }
  },
  data() {
    return {
      readyWatch: false,
      newFacilities: {},
      availTypes: {
        painting: true
      }
    };
  }
};
</script>

<style lang="scss">
.lean-improvement-balance-wrapper {
}
</style>
